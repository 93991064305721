@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

/*boards*/

 .TabExplorerCliente_User {
  display: flex;
  justify-content:center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid var(--borderC);
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);
  padding: 1%;
  overflow: hidden;
}

.TabUser {
  row-gap: 1.5%;
  margin: 0.5%;
  padding: 0, 5%;
  width: 97%;
  height: 92vh;
  display: grid;
  grid-template-columns: 1fr 0.5fr 1.2fr;
  grid-template-rows: 1.5fr 1.5fr;
  column-gap: 1%;

> :nth-child(1) {
    grid-row-start: 1;
    grid-row-end: 4;
    height: 100%;
  }

> :nth-child(2) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 4;
  }

> :nth-child(3) {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    
  }

> :nth-child(4) {
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  
  }

> :nth-child(5) {
    grid-column: 2;
    grid-row-start: 2;
    grid-row-end: 4;}

}

/*ADMIN y client______________________________________________________*/
.TabClient_Super {
    display: grid;
    width: 100%;
    height: 100%; /* Ajustar para ocupar el 100% de la altura de la vista */
    box-sizing: border-box;
    row-gap: 1.5%;
    column-gap: 1%;
    grid-template-columns: 0.6fr 0.4fr 1.2fr; /* Tres columnas */
    grid-template-rows: 0.5fr 0.5fr 0.5fr 2fr;
    
  }
  
  /* Primera columna */
  .TabClient_Super > :nth-child(1) {
    grid-row: 1 / 4; /* Ocupa las tres primeras filas de la primera columna */
    grid-column: 1;
    height: 100%;
  }
  
  /* Segunda columna, dividida en tres filas */
  .TabClient_Super > :nth-child(2) {
    grid-row: 1; /* Primera fila de la segunda columna */
    grid-column: 2;
    height: 100%;
  }
  
  .TabClient_Super > :nth-child(3) {
    grid-row: 2; /* Segunda fila de la segunda columna */
    grid-column: 2;
    height: 100%;
  }
  
  .TabClient_Super > :nth-child(4) {
    grid-row: 3; /* Tercera fila de la segunda columna */
    grid-column: 2;
    height: 100%;
  }
  
  /* Tercera columna */
  .TabClient_Super > :nth-child(5) {
    grid-row: 1 / 4; /* Ocupa las tres primeras filas de la tercera columna */
    grid-column: 3;
    height: 100%;
  }
  
  /* Segunda fila principal con una sola columna */
  .TabClient_Super > :nth-child(6) {
    grid-row: 4; /* Ocupa la cuarta fila completa */
    grid-column: 1 / 4; /* Ocupa las tres columnas */
    height: 100%;
  }

/*Established styles*/
.titulo {
  display: flex;
  width: 98%;
  height: 100%;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid var(--border);
  margin-left: 1%;

  h2 {
    padding: 1%;
    color: var(--title);
    font-family: "Open Sans";
    font-size:clamp(20px, 1.2vw, 25px);
    font-style: normal;
    font-weight: 700;
    line-height: 55%;
  }

}

/* S- Single components*/
/*--------------User board--------------*/
.TabHistoriMensajesU {
  display: grid;
  grid-template-rows: 10% 90%;
  grid-template-columns: 1fr; 
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%; 
  height: 100%;
  

  .container_graph{
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    width: auto;
    gap:1%;
    padding: 0%;


  }

  .filtro {
    max-width: 950px;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 4%;
    padding-top: 10px;
  }
  
  .container-input {
    max-width: 70%;
    min-width: 40%;
    height: 100%;
    width: 40%;
    border-radius: 5px;
    border: 0.5px solid var(--border);
    display: grid;
    grid-template-rows: 1fr 1fr;
    cursor: pointer;
  }
  .css-wt14m6-placeholder {
 
    font-size: 60% !important;
}
  txt {
    
    height: 100%;
    width: 90%;
    color: var(--subtitle);
    font-family: "Open Sans";
    font-size: 60%;
    font-style: normal;
    margin-left: 5%;
  }
  
  input {
    
    appearance: none;
    height: 100%;
    width: 85%;
    border: 0px;
    font-family: "Open Sans";
    font-size: 0.75rem;
    font-style: normal;
    margin-left: 0.4rem;
  }
  
  select {
    position: relative;
    display: block;
    width: 90%;
    height: 100%;
    border: none;
    outline: none;
    font-family: "Open Sans", sans-serif;
    font-size: 80%;
    font-style: normal;
    background: transparent;
    margin-left: 5%;
    cursor: pointer;
    appearance: none;
    background-image: url('../assets/Dropdown.svg'); 
    background-repeat: no-repeat;
    background-position: left 110% center; 
    background-size: 30% 30%;
  }
  
  .container-inputc {
    width: 25%;
    height: 100%;
    min-width: 20%;
    max-width: 30%;
    border-radius: 5px;
    border: 0.5px solid var(--border);
  }
  
  .select-containerC {
    width: 100%;
    height: 22px;
    position: relative;
    display: flex;
    z-index: 3;
    align-items: center; 
    justify-content: flex-end;
    padding-right: 1%;
    border-radius: 0px 0px 5px 5px;
    border-top: 0.5px solid var(--border);
  }
  
  txt2 {
    display: flex;
    height: auto;
    width: 90%;
    color: var(--subtitle);
    font-family: "Open Sans";
    font-size: 60%;
    font-style: normal;
    margin-left: 4%;
  }
  
  .select-containerC .my-select__menu {
    border-radius: 5px;
    height: auto;
    max-height: 70vh;
    overflow-y: auto;
  }
  
  .select-containerC .iconoUserSmall {
    width: 10px;
    height: 10px;
  }

  .iconoUserSmall {
    width: 80px;
    height: auto;
  }
  
  .my-select__option:active {
    background-color: #FFE6F7; 
    color: white; 
  }
  
  .select-de {
    font-size: 0.75rem;
  }
  
  .my-select__control {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  

  .table-containerT {
    margin-top: 5px;
    background: transparent;
    width: 98%;
    max-width: 950px;
    min-width: 500px;
    height: 85%;
    overflow-y: none;
    position: relative;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0); 
    font-size: 80%;  
 }
 
 .table-containerT table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  /*border:1.3px solid var(--border);*/
}
   
    .table-containerT td,
    .table-containerT th {
      padding: 1.5%;
      font-family: "Open Sans";
      vertical-align: middle;
      text-align: left;
      max-height: 80px; 
      overflow-y: none; 
      white-space: normal; 
      word-break: break-all; 
      position: relative;
      text-overflow: ellipsis;
      
    }

    .table-containerT .content {
      display: block;
      max-height: 70px;
      overflow-y: auto; /* Agrega desplazamiento si es necesario */
      white-space: pre-wrap;
    }
    
    .table-containerT th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
      color: var(--subtitle);
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      
    }
    .table-containerT tr:first-child th {
      border-bottom: 1.3px solid #FFB0E7;
      background-color: #FFB0E7;
    }

    .table-containerT th:last-child {
      border-radius: 0px 10px 0px 0px;
    }

    .table-containerT th:first-child {
      border-radius: 10px 0px 0px 0px;
    }

    .table-containerT tbody tr {
      border-left: 1.3px solid var(--border);
      border-right: 1.3px solid var(--border);
      border-bottom: 1.3px solid var(--border);
      background-color: white;
  }
  
    .iconoUserSmall {
      width: 30%;
      max-width: 100px;
      height:auto;
      margin: 1%;
      min-Width:25px;
      max-width:25px;
    }
   .city {
    color: var(--subtitle);
    font-size: clamp(10px, 0.8vw, 12px);
    }
    .externalLink{
      /* width: 100%; */
      font-size: 2.6em;
      /* display: none; */
      color: var(--subtitle);
    }
    .externalLink:hover{
      cursor: pointer;
    }
  }
  
 

  .company {
    display: flex;
    flex-direction: column; 
    align-items: center; 


  .iconoUser {
    width: 100%;
    height: 100%;
}



.iconoBot {
  width: 100%;
  height: 100%;
}

.iconoBotSmall {
  width: 20%;
  max-width: 100px;
  height:auto;
  margin: 1%;
}
}
.filtro .firstc{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; 
  gap: 2%; 
  width: 80%; 
  height: 100%;
  max-width: 100%;
}
.filtro .first{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; 
  gap: 3%; 
  width: 30%; 
  height: 100%;
  max-width: 30%;
}
.filtro .second{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end; 
  width: 70%; 
  height: 100%;
  gap: 1%;
  max-width: 70%;
}

  .pagination{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 40px;
    border-radius: 0px 0px 10px 10px;
    border-left:1.3px solid var(--border);
    border-right:1.3px solid var(--border);
    border-bottom: 1.3px solid var(--border);
    margin-bottom: 0.5%;
    overflow: hidden;
    padding: 1%;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
  }

  .pagination button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  .pagination span {
    margin: 0 10px;
  }
  
  .panel-seleccion-columnas{
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    max-height: 40px;
    gap:1.5%;
  }
 
  /*--------------Admin board--------------*/
/*Client Behavior*/
.GraphBarsSuper {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 15% 15% 60%;
  align-items: center;
  gap: 1.5%;
  border-radius: 5px;
  border: 1px solid var(--borderC);
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);

  .filtro {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 95%;
    height: 90%;
   
  .container-input {
    max-width: 150px;
    min-width: 105px;
    height: 100%;
    width: 30%;
    border-radius: 5px;
    border: 0.5px solid var(--border);
    background: #fff;
    display: grid;
    grid-template-rows: 1fr 1fr;
    cursor: pointer;
  }  

    txt {
      height: 100%;
      width: 90%;
      color: var(--subtitle);
      font-family: "Open Sans";
      font-size: 60%;
      font-style: normal;
      margin-left: 5%;
    }

    input {
      appearance: none;
      height: 100%;
      width: 90%;
      border: 0px;
      font-family: "Open Sans";
      font-size: 0.75rem;
      font-style: normal;
      margin-left: 0.5rem;
    }

    select {
      position: relative;
      display: block;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Open Sans", sans-serif;
      font-size: 80%;
      font-style: normal;
      background: transparent;
      margin-left: 5%;
      cursor: pointer;
      appearance: none;
      background-image: url('../assets/Dropdown.svg'); 
      background-repeat: no-repeat;
      background-position: left 110% center; 
      background-size: 30% 30%;
    }
  }

  .filtro .first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    flex: 1;
    gap: 5%; 
    width: 90%; 
    height: 100%;
  }
  

.filtro .second{
  flex: 1;
  display: flex;
  align-items: center; 
  justify-content: flex-end;
  width: 10%;
  height: 85%;  
}

.filtro .second .container-input {
  height: 100%;
  width: 20%;
  min-width: 150px;
  border-radius: 5px;
  border: 0.5px solid var(--border);
  display: grid;
  cursor: pointer;
} 

.containerBar {
    padding-right: 2%;
    width:98% ;
    height: 90%;
}}

/*--------------Admin board--------------*/
/*Radar frecuency*/
.RadarContent {
  min-width: 250px;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 15% 15% 70%;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--borderC);
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);
  gap:1.5%;
  padding:0.1%;
  overflow: hidden;
  
  .filtroRadarC {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 95%;
    height: 100%;
    margin-left: 5%;
    gap:3%;
    min-height: 24px;
      
    .container-input {
      max-width: 140px;
      min-width: 140px;
      height: 100%;
      width: 30%;
      border-radius: 5px;
      border: 0.5px solid var(--border);
      background: #fff;
      display: grid;
      grid-template-rows: 1fr 1fr;
      cursor: pointer;
    } 

      txt {
        height: 100%;
        width: 90%;
        color: var(--subtitle);
        font-family: "Open Sans";
        font-size: 60%;
        font-style: normal;
        margin-left: 6%;
      }

    select {
      position: relative;
      display: block;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Open Sans", sans-serif;
      font-size: 80%;
      font-style: normal;
      background: transparent;
      margin-left: 6%;
      cursor: pointer;
      appearance: none;
      background-image: url('../assets/Dropdown.svg'); 
      background-repeat: no-repeat;
      background-position: left 110% center;
      background-size: 30% 30%;
    }
  }

    .large-div {
      height: 100%;
      display: grid;
      justify-items: center;
      

      .espacioabajo {

        padding-top: 0%;
        padding-bottom: 0%;
        height: 50%;
      }
    }
   
}
 
    
/*--------------Client board--------------*/
/*User Behavior*/
.RadarContentClient {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 13% 15% 60% 11%;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid var(--borderC);
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);

    .filtroRadarC {
      display: flex;
      flex-direction: row; 
      align-items: center;
      justify-content: flex-start;
      width: 90%;
      height: auto;
      margin-left: 4%;

    .container-input {
      max-width: 150px;
      min-width: 130px;
      min-height: 30px;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 0.5px solid var(--border);
      background: #fff;
      display: grid;
      grid-template-rows: auto 1fr;
      
    } 
    txt {
      height: 100%;
        width: 90%;
        color: var(--subtitle);
        font-family: "Open Sans";
        font-size: 60%;
        font-style: normal;
        margin-left: 5%;
    }
    select {
      position: relative;
      display: block;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Open Sans", sans-serif;
      font-size: 80%;
      font-style: normal;
      background: transparent;
      margin-left: 5%;
      cursor: pointer;
      appearance: none;
      background-image: url('../assets/Dropdown.svg');
      background-repeat: no-repeat;
      background-position: left 110% center;
      background-size: 30% 30%;
    }
    .filtroRadarC .first{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; 
      flex: 1;
      gap: 5%; 
      margin-left: 2.5%; 
      width: 90%; 
      height: 85%;
    }
    .filtroRadarC .first .container-input {
      height: 100%;
      width: 20%;
      min-width: 140px;
      border-radius: 5px;
      border: 0.5px solid var(--border);
      display: grid;
      cursor: pointer;
    }

    }

   
    .large-div {
      height: 100%; 
      display: grid;
      align-items: start; 
      justify-items: center;

    }

    .espacioabajo {
      padding-top: 0%;
      padding-bottom: 0%;
    }
    
    .Base {
      height: 100%;
      width: 100%;
      display: grid;
      align-items: start; 
      justify-items: center;
      color:var(--pink);
      }  
}
/* F- Single components*/

/*User counter - user board */

.GraphContConv {
  display: grid;
  justify-content: center;
  grid-template-columns: 0.7fr 1fr; 
  grid-template-rows: 0.4fr 1fr 3fr;
  width: 100%;

  height: 100%;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  padding: 1.2%;
  gap: 0; 
  

  .filtro {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: auto;
    padding: 0.5%;
    
    .container-input {
      max-width: 11vw;
      min-width: 9vw;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 0.5px solid var(--border);
      background: #FFF;
      display: grid;
      grid-template-rows: 1fr 1fr;
    }

    txt {
      height: 100%;
      width: 90%;
      color: var(--subtitle);
      font-family: "Open Sans";
      font-size: 60%;
      font-style: normal;
      margin-left: 5%;
    }

    input {
      appearance: none;
      height: 100%;
      width: 90%;
      border: 0px;
      font-family: "Open Sans";
      font-size: 60%;
      font-style: normal;
      margin-left: 0.3rem;
    }
  } 

}

.GraphContConv .titulo {
  grid-column: 1 / 3;
  grid-row: 1; 
  
}

.graphCont {
  grid-column: 1 / 2; 
  grid-row: 2 / 4; 
  display: grid; 
  justify-content: center; 
  align-items: center;
}

.Filter {
  display: grid; 
  grid-column: 2 / 3; 
  grid-row: 2 / 3;
  grid-template-columns: 1fr 1fr;
  align-items: center; 
  justify-items: center;
  column-gap: 0%; 
  min-width: 21.5vw;
}

.tablecount{
  width: 100%;
  display: grid;
  grid-column: 2 / 3; 
  grid-row: 3 / 4; 
  align-items: center;  
  justify-items: center;
}

/* Sttles Tables. Explorer */
.table-container {
  background: #fff;
  border-radius: 8px;
  overflow-y: scroll;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: block; 
  width: 80%;
  overflow-y: hidden; 

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    font-size: 80%;
    text-align: left;
    padding: 1% 1% 3% 3%;
  }

  th {
    background-color: #ffb0e7;
    color: #595959;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 183.333% */
    text-transform: uppercase;
  }

  tbody tr {
    border-bottom: 1px solid #eaeaea;
  }

  .color-circle {
    height: 0.6vw;
    width: 0.6vw;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
}

/* Botones  Tables . Explorer------*/
.pagination-controls {
  display: flex;
  justify-content: right;
}

.pagination-controls button {
  border: none;
  background-color: transparent;
  padding: 0.5vh;
  cursor: pointer;
}

.pagination-controls button:disabled {
  cursor: default;
  opacity: 0.5;
}

.icon {
  fill: #333;
  /* Color del ícono */
  width:auto;
  /* Tamaño del ícono */
  height: 3vh;
}


 /* ---------------------------------------------------------- */


/* repeated graphs

/* Component of user information card */
.infoUserCard {
  display: flex; 
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 170px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  background: var(--lightblue);
  border: 1px solid white;
  justify-content: center;
  align-items: center;
  color:white;
  
  
  .card{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start; 
    border-radius: 5px;
    padding: 5%;
  }
  .card .row1 {
    flex-grow: 8; 
    width: 100%;
    z-index: 3;
  }
  
  .card .row2 {
    flex-grow: 2; 
    width: 100%;
    z-index: 3;
  }

  .titulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: auto;
    margin-top: 5%;
    color: white;
    font-family: "Open Sans";
    font-size: 1.8vw;
    font-style: normal;
    font-weight: 700;
    line-height: 90%;
    border: 0;

    nombre {
      margin-top: 3%;
      font-size: 1.5vw;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: 0px;
    }
  }

  .fecha {
    display: flex;
    align-items: center;
    gap: 5%;
    width: 100%;
    height: 20%;
    margin: 0% 0% 10%;
    
    .txt {
      color: white;
      font-family: "Open Sans";
      font-size: 1.2vw;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

    }

    .calendar {
      fill: var(--lightpink);
      width: 6%;
      height: auto;
    }
  }

}



  /* SimpleRadialBarChart TALKS COUNT */
  .GraphRadial {
    display: grid;
    grid-template-rows: 0.4fr 0.3fr 2.5fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.6);
    border-radius: 5px;
    padding: 1%;
  
  
  
    .divGraficaRadialBar {
      display: grid;
  
      margin-right: 2.5%;
      height: 27vh;
      grid-template-columns: 1fr 0.75fr;
    }
  
    p {
      font-size: 1.25rem;
      height: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    }
  
    .fechapastel {
      padding-top: 1%;
      display: flex;
      height: 100%;
      padding-left: 2%;
      justify-content: flex-end;
      ;
      align-items: center;
      gap: 1rem;
  
      .filtro {
        display: flex;
        justify-content: center;
        width: 50%;
        height: 100%;
        align-items: flex-start;
        gap: 1rem;
  
        input {
          height: 100%;
          padding: 1%;
          padding-right: 1%;
          border-radius: 5px;
          border: 0.5px solid var(--border);
          background: #FFF;
        }
  
        select {
          height: 100%;
          padding-left: 1%;
          padding-right: 1%;
          border-radius: 5px;
          border: 0.5px solid var(--border);
          background: #FFF;
  
        }
      }
  
      input {
        height: 100%;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
      }
  
    }
  
    .divGraficaPie {
      height: 27vh;
      display: flex;
      align-items: center;
  
      .recharts-legend-wrapper {
        padding-right: 3%;
      }
    }
  
  
  
  /* Filtro clientes */
  .filtrocliente {
  
    place-content: center;
    display: block;
  
    .filtro {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 10%;
      align-items: flex-start;
      gap: 1rem;
  
      input {
        height: 100%;
        width: 100%;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
      }
  
      select {
        height: 100%;
        width: 80%;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
  
      }
    }
  
    .table-container {
      height: 27vh;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  
  /* PieChart Conversation duration */
  .GraphConvDur {
    display: grid;
    grid-template-rows: 0.4fr 0.3fr 2.5fr;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.6);
    border-radius: 5px;
    padding: 1%;
  
    .fechapastel {
      display: flex;
      justify-content: space-between;
  
      input {
        height: 100%;
        width: 40%;
        margin-left: 5%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
      }
    }
  
    .filtro {
      display: flex;
      margin-right: 5%;
      justify-content: flex-end;
      ;
      width: 40%;
      height: 100%;
      align-items: flex-end;
      gap: 1rem;
  
      input {
        height: 100%;
        width: 100%;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
      }
  
      select {
        height: 100%;
        width: 100%;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
  
      }
    }
  
    p {
      font-size: 1.25rem;
      height: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .fechapastel {
      padding-top: 1%;
      display: flex;
      height: 100%;
      padding-left: 2%;
      align-items: flex-start;
      gap: 1rem;
  
      input {
        height: 100%;
        padding-left: 1%;
        padding-right: 1%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
      }
  
    }
  
    .divGraficaPie {
      height: 27vh;
      display: flex;
      align-items: center;
  
      .recharts-legend-wrapper {
        padding-right: 3%;
      }
    }
  }
  
  /*Styles Cards*/
  .Cards {
    background-color: var(--lightblue);
    grid-row: 1;
    height: 100%;
    min-width: 150px;
    display: grid;
    place-content: center;
    border: 1px solid white;
    border-radius: 10px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);
    padding: 2.5%;
    
    
    .numbmesages {
      color: white;
      display: flex;
      justify-content: center;
      font-size: 2vw;
      font-weight: 700;
    }
  
    h3 {
      color: white;
      font-size:80%;
      text-wrap: wrap;
      text-align: center;
      font-weight: 400;
    }
    /* CARD ADMIN ONLY*/

  .container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70%;
    box-shadow: none;
    background: none;
    column-gap: 1%;
    margin: 0;

  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .txt {

    color: white;
    font-size: 0.8vw;
    text-wrap: wrap;
    text-align: left;
    font-weight: 400;
    margin-bottom: 5%;
  }
  
  .line {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vw; 
    line-height: 5vw; 

  }
  }

  .Cards ul li{
    list-style-type: none;
  }
  
  /* -----------------------------------Explorer------------------------- */
  
  /*Styles Boards 2rows 2columns*/
  .TabExplorer {
    row-gap: 1%;
    margin: 0.5%;
    padding: 0, 5%;
    height: 95vh;
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    grid-template-rows: 50% 50%;
    column-gap: 13px;
  
    > :nth-child(1) {
      grid-row: 1;
      grid-column: 2;
      height: 100%;
    }
  
    > :nth-child(2) {
      grid-row: 2;
      grid-column: 2;
      height: 100%;
    }
  
    > :nth-child(3) {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column: 1;
      height: 100%;
    }
  }
  
 
  
  /*HISTORY TABLE*/
.TabHistoriMensajes {
  display: grid;
  grid-template-rows: 0.1fr 0.8fr 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(102, 102, 102, 0.6);
  border-radius: 5px;
  padding: 1%;

  .table-container {
    width: 95%;
    height: 55vh;
    overflow-y: auto;
    

    td,
    th {
      padding-top: 1%;
      font-family: "Open Sans";
      vertical-align: top;
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      /* Para un diseño más limpio */
    }

    th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 10;
      color: var(--subtitle);

      font-family: "Open Sans";
      font-size: 100%;
      padding-left: 5%;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
    }
  }

  p {
    font-size: 10%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .de {
    color: var(--pink) !important;
    text-align: center;
    font-family: "Open Sans";
    font-size: 70%;
    font-style: normal;
    font-weight: 700;

  }

  .de .icono {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5%;
    padding: 1%;
  }

  .itemfecha {
    color: var(--text);
    font-family: "Open Sans";
    font-size: 90%;
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;
    text-transform: capitalize;

  }

  .mensajeid {
    color: var(--subtitle);
    font-family: "Open Sans";
    font-size: 10%;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-transform: capitalize;

  }

  .mensaje {
    color: var(--text);
    font-family: "Open Sans";
    font-size: 80%;
    font-style: normal;
    font-weight: 400;
    line-height: 136.364%;
    text-transform: capitalize;

  }


  .fechapastel {
    
    display: flex;
    height: 50%;
    padding-left: 1%;
    
    align-items: flex-start;
    margin: 1.5%;
    gap: 1rem;

    input {
      height: 100%;
      padding-left: 1%;
      padding-right: 1%;
      border-radius: 5px;
      border: 0.5px solid var(--border);
      background: #FFF;
    }

  }

}

/*Repeted graphs*/
/*trends*/
.GraphBars_t {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 10% 10% 80%;
  align-items: center;

  .filtro {
    max-width: 900px;
    
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 40px;
    min-height: 40px;
    gap:2%;

  .container-input {
    max-width: 150px;
    min-width: 105px;
    max-height: 40px;
    height: 100%;
    width: 30%;
    border-radius: 5px;
    border: 0.5px solid var(--border);
    background: #fff;
    display: grid;
    grid-template-rows: 1fr 1fr;
    cursor: pointer;
    margin-left: 4%;
  }  

    txt {
      height: 100%;
      width: 90%;
      color: var(--subtitle);
      font-family: "Open Sans";
      font-size: 60%;
      font-style: normal;
      margin-left: 5%;
    }

    input {
      appearance: none;
      height: 100%;
      width: 90%;
      border: 0px;
      font-family: "Open Sans";
      font-size: 0.75rem;
      font-style: normal;
      margin-left: 0.5rem;
    }

    select {
      position: relative;
      display: block;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Open Sans", sans-serif;
      font-size: 80%;
      font-style: normal;
      background: transparent;
      margin-left: 5%;
      cursor: pointer;
      appearance: none;
      background-image: url('../assets/Dropdown.svg'); 
      background-repeat: no-repeat;
      background-position: left 110% center; 
      background-size: 30% 30%;
    }
  }

  .filtro .first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    gap: 1%; 
    width: 100%; 
    height: 100%;
  }
  

.filtro .second{
  flex: 1;
  display: flex;
  align-items: center; 
  justify-content: flex-end;
  width: 50%; 
  height: 85%;  
  margin-right: 3%;
}

.filtro .second .container-input {
height: 100%;
width: 20%;

border-radius: 5px;
border: 0.5px solid var(--border);
display: grid;
cursor: pointer;
} 
.contenedor-dos-columnas {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 1%;
}
.columna-15 {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 22%;
  height: 98%;
}

.columna-85 {
  display:flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 98%;
  gap:0;
  
.containerBar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width:100%;
  height: 100%;
}
}

.container-input2 {
  height: auto;
  width: 100%;
  border-radius: 5px;
  border: 0.5px solid var(--border);
  background: #fff;
  padding: 4%;
  margin: 1%;
  margin-bottom: 20px;
  
h1{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size:clamp(15px, 1vw, 20px);
  color:var(--subtitle);
  font-weight: 650;
  margin-left: 3%;
  width: 93%;
  padding-bottom: 2%;
  border-bottom: 1px solid var(--text);
}

h2{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size:clamp(12px, 0.8vw, 15px);
  color:var(--subtitle);
  font-weight: 600;
  margin-left: 10%;
  margin-top: 7%;
  margin-bottom: 7%;
}

}  

}

/*users behavior*/
.GraphBars {
  width: 100%;
  height: 90%;
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 15% 14% 71%;
  align-items: center;
  gap: 1%;
  border-radius: 5px;
  border: 1px solid var(--borderC);
  background: #FFF;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.6);

  .filtro {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 90%;

  .container-input {
    max-width: 150px;
    min-width: 105px;
    height: 100%;
    width: 30%;
    border-radius: 5px;
    border: 0.5px solid var(--border);
    background: #fff;
    display: grid;
    grid-template-rows: 1fr 1fr;
    cursor: pointer;
  }  

    txt {
      height: 100%;
      width: 90%;
      color: var(--subtitle);
      font-family: "Open Sans";
      font-size: 60%;
      font-style: normal;
      margin-left: 5%;
    }

    input {
      appearance: none;
      height: 100%;
      width: 90%;
      border: 0px;
      font-family: "Open Sans";
      font-size: 0.75rem;
      font-style: normal;
      margin-left: 0.5rem;
    }

    select {
      position: relative;
      display: block;
      width: 90%;
      height: 100%;
      border: none;
      outline: none;
      font-family: "Open Sans", sans-serif;
      font-size: 80%;
      font-style: normal;
      background: transparent;
      margin-left: 5%;
      cursor: pointer;
      appearance: none;
      background-image: url('../assets/Dropdown.svg'); 
      background-repeat: no-repeat;
      background-position: left 110% center; 
      background-size: 30% 30%;
    }
  }

  .filtro .first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start; 
    flex: 1;
    gap: 4%; 
    width: 50%; 
    height: 85%;
    margin-left: 3%;
  }
  

.filtro .second{
  flex: 1;
  display: flex;
  align-items: center; 
  justify-content: flex-end;
  width: 50%; 
  height: 85%;  
  margin-right: 3%;
}

.filtro .second .container-input {
height: 100%;
width: 20%;
min-width: 150px;
border-radius: 5px;
border: 0.5px solid var(--border);
display: grid;
cursor: pointer;
} 

.containerBar {
  padding-right: 2%;
  width:98% ;
  height: 90%;
}
}

/*Responsive for mobile*/

@media (max-height: 480px) {

    .titulo {
      h2 {font-size: 1.4vw;}}
  
    .RadarContent {
      grid-template-rows: 15% 15% 70%;
   
      .filtroRadarC {
        gap: 2%;
  
        txt {
          font-size: 35%;
        }
  
        select {
          font-size: 40%;
        }
      }
  
      .filtroRadarC .second .container-input {
        height: 100%;
        width: 20%;
        min-width: 80px;
      } 
  
      .filtroRadarC .first .container-input {
        height: 100%;
        width: 20%;
        min-width: 80px;
      } 
    }
  
  
    /*Repeted graphs*/
    .GraphBars {
      display: grid;
      .filtro {
        .container-input {
          max-width: 85px;
          min-width: 85px;
        }  
        txt {
          height: 100%;
          width: 90%;
          font-size: 43%
        }
  
        input {
          appearance: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
        }
  
        select {
          width: 90%;
          height: 100%;
          font-size: 2vh;
        }
      }
  
      .filtro .first {
        width: 90%;
        height: 100%;
      }
  
  
      .filtro .second {
        width: 90%;
        height: 100%;
      }
  
      .filtro .second .container-input {
        height: 100%;
        width: 20%;
        min-width: 100px;
      } 
      
       .TabHistoriMensajesU{
      .table-container {
        table {
          width: 100%;
          border-collapse: collapse;
        }
    
        th,td {
          font-size: 60%;
          max-height: 70px;
        }

        th {line-height: 183.333%;}
      }
      .filtro {

        .container-inputc {
          max-width: 90px;
          min-height: 30px;
        }
        txt {
          font-size: 50%;
        }
    
        input {
          height: 100%;
          width: 20%;
          min-width: 95px;
          font-size: 60%;
    
        }
        select {
          height: 100%;
          font-size: 45%;
        }
        
        txt2 {
          font-size: 50%;
    
        }
        
      }
    }
    }
  
    /*Client Behavior*/
    .GraphBarsSuper {
      display: grid;
  
      .filtro {
        .container-input {
          max-width: 85px;
          min-width: 85px;
        }
  
        txt {
          height: 100%;
          width: 90%;
          font-size: 43%
        }
  
        input {
          appearance: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
        }
  
        select {
          width: 90%;
          height: 100%;
          font-size: 2vh;
        }
      }
  
      .filtro .first {
        width: 90%;
        height: 100%;
      }
  
      .filtro .second {
        width: 90%;
        height: 100%;
      }
  
      .filtro .second .container-input {
        height: 100%;
        width: 20%;
        min-width: 100px;
      } 
  
    }
  
    /*Styles Cards*/
    .Cards {
      border-radius: 5px;
      .numbmesages {
        font-size: 2vw;}
  
      h3 {
        font-size: 1.1vw;
      }
    }
  
    /* Sttles Tables. Explorer */
    .TabHistoriMensajesU{
      .table-container {
        table {
          width: 100%;
          border-collapse: collapse;
        }
    
        th,td {
          font-size: 60%;
          max-height: 70px;
        }

        th {line-height: 183.333%;}
      }
      .filtro {
        .container-inputc {
          width: 20%;
          min-width: 90px;
          max-width: 100px;
      }
        .container-input {
          max-width: 110px;
          min-width: 90px;
          width: 30%;
      }
        txt {
          font-size: 50%;
        }
    
        input {
          height: 100%;
          width: 20%;
          min-width: 75px;
          font-size: 60%;
    
        }
        select {
          height: 100%;
          font-size: 45%;
        }
        
        txt2 {
          font-size: 50%;
    
        }
        
      }
    }
    .filtro .first {
      gap: 3%;
      width: 30%;
  }
  .filtro .second {
   
    width: 70%;
    height: 100%;
    gap: 1%;
}
    .css-wt14m6-placeholder {
   
 
      font-size: 45% !important;
  
  }
    /* USER */
    .TabUser {
      width: 95%;
      height: 94vh;
    }
  
  
    /*User counter - user board */
  
  .GraphContConv {
    gap: 0; 
    
  
    .filtro {
     
      padding: 0.5%;
      
      .container-input {
        max-width: 25vw;
        min-width: 11.5vw;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
        display: grid;
        grid-template-rows: 50% 50%;
      }
  
      txt {
        height: 100%;
        width: 90%;
        font-size: 45%;
        margin-left: 2%;
      }
  
      input {
        appearance: none;
        height: 100%;
        width: 90%;
        
        font-size: 40%;
        margin-left:3%;
      }
    } 
  
  }
  
  
  .Filter {
    grid-template-columns: 50% 50%;
    align-items: center; 
    justify-items: center;
    column-gap: 0%; 
    min-width: 30vw;
    margin-block: 1%;
  }
  /* Sttles Tables. Explorer */
  .table-container {
   
  font-size: 50%;
  
    th,
    td {
      font-size: 100%;
      text-align: left;
      padding: 1% 1% 2% 3%;
    }
  
    th {
      line-height:200%;
      text-transform: uppercase;
    }
  
  
    .color-circle {
      margin-right: 5%;
    }
    .GraphBars_t  {
      display: grid;
     
      .filtro {
        .container-input {
          max-width: 85px;
          min-width: 85px;

        }  
        txt {
          height: 100%;
          width: 90%;
          font-size: 43%
        }
  
        input {
          appearance: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
        }
  
        select {
          width: 90%;
          height: 100%;
          font-size: 2vh;
        }
      }
  
      .filtro .first {
        width: 90%;
        height: 100%;
      }
  
  
      .filtro .second {
        width: 90%;
        height: 100%;
      }
  
      .filtro .second .container-input {
        height: 100%;
        width: 20%;
        min-width: 100px;
      }  }
  }
  
  /* Botones  Tables . Explorer------*/
  
  .pagination-controls button {
    padding: 0.3vh;
  }
  .GraphBars_t  {
    display: grid;
   
    .filtro {
      .container-input {
        max-width: 85px;
        min-width: 85px;

      }  
      txt {
        height: 100%;
        width: 90%;
        font-size: 43%
      }

      input {
        appearance: none;
        height: 100%;
        width: 90%;
        font-size: 2vh;
      }

      select {
        width: 90%;
        height: 100%;
        font-size: 2vh;
      }
    }

    .filtro .first {
      width: 90%;
      height: 100%;
    }


    .filtro .second {
      width: 90%;
      height: 100%;
    }

    .filtro .second .container-input {
      height: 100%;
      width: 20%;
      min-width: 100px;
    }  }
  
  }
  
  
  
  @media (max-height: 390px) {

    .GraphBars_t  {
      display: grid;
     
      .filtro {
        .container-input {
          max-width: 85px;
          min-width: 85px;

        }  
        txt {
          height: 100%;
          width: 90%;
          font-size: 43%
        }
  
        input {
          appearance: none;
          height: 100%;
          width: 90%;
          font-size: 2vh;
        }
  
        select {
          width: 90%;
          height: 100%;
          font-size: 2vh;
        }
      }
  
      .filtro .first {
        width: 90%;
        height: 100%;
      }
  
  
      .filtro .second {
        width: 90%;
        height: 100%;
      }
  
      .filtro .second .container-input {
        height: 100%;
        width: 20%;
        min-width: 100px;
      }  }
    
    /* SUPERUSER  */
    .RadarContent {
        grid-template-rows: 15% 15% 70%;
        .filtroRadarC {
          gap: 2%;
    
          txt {
            font-size: 35%;
          }
    
          select {
            font-size: 40%;
          }
        }
    
        .filtroRadarC .second .container-input {
          height: 100%;
          width: 20%;
          min-width: 80px;
        } 
    
        .filtroRadarC .first .container-input {
          height: 100%;
          width: 20%;
          min-width: 80px;
        } 
      }
      
    .infoUserCard {
      min-width: 130px;
    }
  
    .Cards {
      border-radius: 5px;
      .numbmesages {
        font-size: 1.8vw;
      }
  
      h3 {
        font-size: 1.1vw;
      }
    }
  
  
    .titulo {
      h2 {
        font-size: 1.4vw;
      }
    }
  
  
    /* Sttles Tables. Explorer */
    .table-container {
  
      width: 80%;
  
      table {
        width: 100%;
        border-collapse: collapse;
      }
  
      th,
      td {
        font-size: 50%;
  
      }
  
      th {
  
        line-height: 183.333%;
  
      }
  
  
  
      .color-circle {
        height: 0.6vw;
        width: 0.6vw;
        border-radius: 50%;
        display: inline-block;
        margin-right: 8px;
      }
    }
    

  /* USER */
  
    /* USER */
    .TabUser {
      width: 93%;
      height: 93vh;
    }
  
  
    /*User counter - user board */
  
  .GraphContConv {
    gap: 0; 
    
  
    .filtro {
     
      padding: 0.5%;
      
      .container-input {
        max-width: 25vw;
        min-width: 11.5vw;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        border: 0.5px solid var(--border);
        background: #FFF;
        display: grid;
        grid-template-rows: 50% 50%;
      }
  
      txt {
        height: 100%;
        width: 93%;
        font-size: 40%;
        margin-left: 2%;
      }
  
      input {
        appearance: none;
        height: 100%;
        width: 93%;
        
        font-size: 40%;
        margin-left:3%;
      }
    } 
  
  }
  
  
  .Filter {
    grid-template-columns: 50% 50%;
    column-gap: 0%; 
    min-width: 25vw;
    margin-block: 0.5%;
  }
  /* Sttles Tables. Explorer */
  .table-container {
   
  font-size: 45%;
  width: 85%;
    th,
    td {
      font-size: 100%;
      text-align: left;
      padding: 1% 1% 2% 3%;
    }
  
    th {
      line-height:200%;
      text-transform: uppercase;
    }
  
  
    .color-circle {
      margin-right: 5%;
    }
  }
  
  /* Botones  Tables . Explorer------*/
  
  .pagination-controls button {
    padding: 0.3vh;
  }
  }
  
  @media (max-height: 290px){
    
  
  /* USER */
  .TabUser {
    height: 90vh;
  }
  
    /*User counter - user board */
  
  .GraphContConv {
    gap: 0; 
    .filtro {
      padding: 0.5%;
      .container-input {
        max-width: 13vw;
        min-width:11vw;
        grid-template-rows: 50% 50%;
      }
  
      txt {
        height: 100%;
        width: 90%;
        font-size: 30%;
        margin-left: 2%;
      }
  
      input {
        appearance: none;
        height: 100%;
        width: 95%;
        
        font-size: 30%;
        margin-left:2%;
      }
    } 
  
  }
  
  
  .Filter {
    min-width: 30vw;
    margin-block: 0.5%;
  }
  /* Sttles Tables. Explorer */
  .table-container {
   
  
  width: 85%;
    th,
    td {
     font-size: 50%;
      text-align: left;
      padding: 1% 1% 2% 3%;
    }
  
    th {
      line-height:100%;
      text-transform: uppercase;
    }
  
  
    .color-circle {
      margin-right: 2.5%;
    }
    
  }
  /* Botones  Tables . Explorer------*/
  .pagination-controls {
  max-height: 4vh;
  min-height: 1vh;
  }
  
  .pagination-controls button {
  height: 50%;
  padding: 0%;
  
  }
  
  
  .icon {
    height: 4vh;
  }
  

  }