 /*responsive content*/
 .datav-container{
  background-color: white;
    overflow: hidden;
    height:100vh;
    width:100vw;
  }
  
  .distribution {
    display: flex;
    justify-content: center; /* Centrar la gráfica horizontalmente */
    align-items: center; /* Centrar la gráfica verticalmente */
    background-color: white;
    height:92vh;
     /* Oculta el contenido que se desborde del contenedor */
  }

.container_graph{
  display: flex;
  justify-content: flex-start; /* Centrar la gráfica horizontalmente */
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 1%;
  overflow-x: auto;
  
}