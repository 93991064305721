* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "ColorPalette.css";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.flex {
  display: flex;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 6%;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  max-width: 90%; 
  height: 15%;
}

.cont {
  display: grid;
  grid-row: 1fr 0.5fr;
  flex-direction: column;
  justify-content: center;
  gap:2.5vh ;
  width: 100%;
  background-color: white;
  box-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 0.3);
  border-radius: 1vw;
  align-items: center;
  padding-inline: 2%;
  padding-block: 3%;
  grid-area: 2vh;
}

text1 {
  font-size: 2.7vw;
  color: var(--darkblue);
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}

.icono {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icono svg {
  width: 10%; 
  height: auto;

}
.icono .svg path {
  stroke: var(--pink);
}



.close-button {
display: flex;
width: max-content;
transform: translateY(106%);
right: 0;
justify-self: right;
background: transparent;
border: none;
cursor: pointer;
z-index: 9999;
}


.close-icon {
width: 5%;
min-width: 30px;
max-width: 30px;
height: auto;
color: var(--darkblue);
padding: 0.5%;
}

.custom-icon {
fill: #333;
height: 3vh;
}


/* Popup.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sidebar-collapsed .overlay {
  margin-left: calc(min(var(--sidebar-width-collapse), 160px));
      width: calc(100vw - min(var(--sidebar-width-collapse), 160px));
      transition: margin-left 0.2s ease, width 0.2s ease;
  }
  
  .sidebar-expanded .overlay {
    margin-left: calc( min(var(--sidebar-width-Expand), 240px));
      width: calc(100vw - min(var(--sidebar-width-Expand), 240px));
      transition: margin-left 0.2s ease, width 0.2s ease;
  }

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 90%;
  overflow: hidden;
  height: auto;
}

.modal-content {
  display: grid;
  grid-template-rows: 5% 90%;
  position: relative;
  height: 100%;
}

.headert {
  background-color: transparent; 
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinea el contenido a la derecha */
  height: 30px;
  padding: 0.1%;
  z-index: 999;
}


.table-popup {
  overflow-y: none;
  width: 95%;
  height: 100%;
  margin-left: 2.5%;
  margin-top: 1%;
  position: relative;
}

.table-popup table {
  max-height: 400px;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.table-popup th,
.table-popup td {
  padding: 1.5%;
  font-family: "Open Sans";
  vertical-align: top;
  text-align: left;
  max-height: 80px;
  overflow-y: hidden; 
  white-space: normal;
  text-overflow: ellipsis;
}

.table-popup .cell-content {
  display: block;
  max-height: 80px;
  overflow-y: auto;
  white-space: pre-wrap;
}


.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 1rem; 
  margin: 0.5% 2% 1% 2%;
}

.button-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.button{
  padding: 1%;
}
.txtexport{

  font-family: "Open Sans";
  margin-bottom: 1%;
}
.title {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  width: 100%;
  /* justify-content: center; */
  height: 100%;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid var(--border);
  margin-left: 1%;

  h2 {
    padding: 1%;
    color: var(--title);
    font-family: "Open Sans";
    font-size:clamp(20px, 1.2vw, 25px);
    font-style: normal;
    font-weight: 700;
    line-height: 55%;
  }

}