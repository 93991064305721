* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import "ColorPalette.css";

/*Template Basepage after authentication */
.basepage {
    display: flex;
    max-height: 100vh;
    max-width: 100vw;
    flex-direction: column;
}

.flex {
    display: flex;
}

.AuthenticationStatus-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(var(--darkblue), var(--pink));
}

.container {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1%;
    background-color: white;
    box-shadow: 0 0.5vw 0.5vw rgba(0, 0, 0, 0.3);
    border-radius: 1vw;
}

.container>* {
    margin-top: 2.5%;
    margin-bottom: 2.52%;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10vw;
    height: auto;
}

.logo svg {
    width: 100%;
    height: auto;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1%;
    width: 100%;
}

.text1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 3vw;
    font-weight: 700;
    color: var(--darkblue);
}

.text2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.5vw;
    font-weight: 700;
    color: var(--pink);
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
}


.submit-button {
    padding: 1vw 3vw;
    background-color: var(--pink);
    border: none;
    border-radius: 1vw;
    color: var(--lightgrey);
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 400;
    line-height: 0.6vw;
    letter-spacing: 0.05vw;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
        border: none;
        outline: none;
        background-color: var(--pinkselected);
    }
    &:focus {
        outline: none;
        box-shadow: 0 0 0 0.1vw var(--Darkpink);
    }
}

/* Media query para pantallas pequeñas */
@media screen and (max-width: 780px) {
    .container {
        width: 80%; 
        border-radius: 2vw;
    }
    .container>* {
        margin-top: 5%;
        margin-bottom:5%;
    }

    .logo {
        width: 30%; 
    }

    .text1 {
        font-size: 8vw; 
    }

    .text2 {
        font-size: 5vw; 
    }

    .submit-button {
        padding: 4vw 8vw; 
        font-size: 5vw; 
        border-radius: 4vw;
    }
}